import { Button, Chip, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar } from "@mui/material";
import React from "react";
import Fetch from "../../Fetch";
import AddMessageDialog from "./addmessage";

const PageMessage = props => {
    const [loading, setLoading] = React.useState(true)
    const [data, setData] = React.useState([])
    const [open, setOpen] = React.useState(false)

    React.useEffect(() => {
        load()
    }, [])

    const load = async () => {
        setLoading(true)
        const result = await Fetch.get('/message?limit=10')
        setData(result.data)
        setLoading(false)
    }

    const addClicked = async () => {
        setOpen(true);
    }

    const refreshClicked = async () => {
        load();
    }

    return <div>
        <TableContainer component="paper">
            <Toolbar>
                <Button onClick={addClicked} variant="contained">Tambah</Button>
                <Button onClick={refreshClicked} variant="contained" style={{ marginLeft: 10 }}>Refresh</Button>
            </Toolbar>
            {loading ? <CircularProgress /> :
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Tanggal</TableCell>
                            <TableCell>Tujuan</TableCell>
                            <TableCell>Pesan</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.map(v => {
                                const status = v.last_status;
                                return (<TableRow>
                                    <TableCell>{v.created_at}</TableCell>
                                    <TableCell>{v.destination}</TableCell>
                                    <TableCell>{v.message}</TableCell>
                                    <TableCell><Chip color={status === 0 || status === 1 ? "warning" : status === 2 ? "info" : status === 3 ? "success" : "primary"}
                                        label={status === 0 || status === 1 ? "pending" : status === 2 ? "terkirim" : status === 3 ? "terbaca" : "gagal"} /></TableCell>
                                </TableRow>)
                            })
                        }
                    </TableBody>
                </Table>
            }
        </TableContainer>
        <AddMessageDialog open={open} onClose={() => setOpen(false)} />
    </div>
}

export default PageMessage;