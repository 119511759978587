import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import React from "react";
import { useForm } from "react-hook-form";
import Fetch from '../../Fetch';

const AddMessageDialog = props => {
    const [loading, setLoading] = React.useState(false)
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        setLoading(true)
        try {
            await Fetch.post('/message', data)
            setLoading(false)
            onClose();
        } catch (e) {
            console.log(e)
            setLoading(false)
        }
    }

    const onClose = () => {
        setValue('destination', '');
        setValue('message', '')
        setValue('tag', '')
        props.onClose()
    }

    return <Dialog {...props}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Kirim pesan</DialogTitle>
            <DialogContent>
                <TextField autoFocus id="destination" label="No HP Tujuan" type="phone" variant="standard" fullWidth
                    helperText="gunakan kode negara untuk kirim ke nomor luar negeri" required error={errors.destination ? true : false}
                    {...register('destination', { required: true, pattern: /^\d+$/ })} />
                <div style={{ height: 32 }} />
                <TextField id="message" label="Pesan" type="text" variant="standard" fullWidth
                    required multiline maxRows={4}  {...register('message', { required: true })} error={errors.message ? true : false} />
                <div style={{ height: 32 }} />
                <TextField id="tag" label="Tag" type="text" variant="standard" fullWidth helperText="contoh: kerja, project_a, project_b"
                    {...register('tag')} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button disabled={loading} type="submit">Kirim</Button>
            </DialogActions>
        </form>
    </Dialog>
}

export default AddMessageDialog;