import React from 'react'
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import fireApp from '../firebase';
import { useHistory } from 'react-router-dom';
import Fetch from '../Fetch';
import { Alert, Box, Button, Card, CardContent, Container, CssBaseline, Typography } from '@mui/material';
const provider = new GoogleAuthProvider();
const auth = getAuth(fireApp);
auth.languageCode = 'id';


const LoginPage = props => {
    const history = useHistory()
    const [loading, setLoading] = React.useState(false);

    const login = async () => {
        try {
            await Fetch.post('/login', {})
            setLoading(false)
            history.replace('/')
        } catch (e) {
            console.log(e)
            setLoading(false)
        }
    }

    const onLoginClicked = () => {
        setLoading(true)
        signInWithPopup(auth, provider).then(result => {
            login();
        }).catch(error => {
            console.log(error)
            setLoading(false)
        })
    }

    return <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            <Card >
                <CardContent>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <Typography variant="h4" component="h4" marginBottom={2}>
                            Kode WA
                        </Typography>
                        <Alert icon={false} severity="success">
                            Selamat datang di kode WA. Saat ini hanya tersedia login menggunakan Google
                        </Alert>
                        <div style={{ height: 12 }} />
                        <Button onClick={onLoginClicked} disabled={loading} variant="contained" size="large" >Login with Google</Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    </Container>
}

export default LoginPage