import './App.css';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import PageLogin from './page/login'
import { StoreProvider } from './store/store';
import DefaultRoute from './route/defaultroute';
import PageDashboard from './page/dashboard/dashboard';
import PageMessage from './page/message/message';
import PageSetting from './page/setting/setting';
import fireApp from './firebase'
import { getAuth } from "firebase/auth";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const CheckLogin = () => {
  if (!getAuth(fireApp).currentUser) {
    return <Redirect to="/login" />
  }
  return <Redirect to="/message" />
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <StoreProvider>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={CheckLogin} />
              <Route exact path='/login' component={PageLogin} />
              <DefaultRoute path="/dashboard" component={PageDashboard} />
              <DefaultRoute path="/message" component={PageMessage} />
              <DefaultRoute path="/setting" component={PageSetting} />
            </Switch>
          </BrowserRouter>
        </StoreProvider>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
