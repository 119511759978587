import { initializeApp } from 'firebase/app';

var firebaseConfig = {
    apiKey: "AIzaSyBec6-Ef0pNPrIBGE5LbgMZ7YiYWCyRQ50",
    authDomain: "bintan-9a5ed.firebaseapp.com",
    projectId: "bintan-9a5ed",
    storageBucket: "bintan-9a5ed.appspot.com",
    messagingSenderId: "656526388134",
    appId: "1:656526388134:web:b685f3e893c00477ddbd67",
    measurementId: "G-4YMJNXWL7R"
};

const app = initializeApp(firebaseConfig);

export default app;