import { CardContent, Card, CircularProgress, TextField, Typography } from "@mui/material";
import React from "react";
import Fetch from "../../Fetch";

const PageSetting = props => {
    const [secret, setSecret] = React.useState('')

    React.useEffect(() => {
        loadSecret();
    }, [])

    const loadSecret = async () => {
        const result = await Fetch.get('/setting')
        setSecret(result.secret)
    }

    const str = `Penggunaan API

    [POST] https://api.kodewa.com/send
    [HEADER] Client-Secret: ${secret}
    [BODY-JSON] {"destination": "081234567890", "message": "your message", "tag": ""}


    Sample CURL
    
    curl --request POST -H "Client-Secret: ${secret}" --data '{"destination": "081234567890", "message": "your message", "tag": ""}' https://api.kodewa.com/send
    `

    return (secret === '' ? <CircularProgress /> : <div>
        <div style={{ marginTop: 32 }} />
        <TextField defaultValue={secret} label="Client secret" variant="standard" fullWidth disabled />
        <div style={{ marginTop: 32 }} />
        <Card>
            <CardContent>
                <Typography color="text.secondary" gutterBottom>
                    Penggunaan API
                </Typography>
                <Typography marginTop={2}>
                    <code>
                        [POST] https://api.kodewa.com/send<br />
                        [HEADER] Client-Secret: {secret}<br />
                        {`[BODY-JSON] {"destination": "[phone number]", "message": "[your message]", "tag": ""}`}
                    </code>
                </Typography>
                <Typography color="text.secondary" marginTop={4}>
                    Contoh menggunakan CURL
                </Typography>
                <Typography marginTop={2}>
                    <code>
                        {`curl --request POST -H "Client-Secret: ${secret}" --data '{"destination": "[phone number]", "message": "[your message]", "tag": ""}' https://api.kodewa.com/send`}
                    </code>
                </Typography>
            </CardContent>
        </Card>
    </div>)
}

export default PageSetting;