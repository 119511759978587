import React, { useEffect, useReducer } from 'react';
import fireApp from './../firebase';
import { getAuth } from "firebase/auth";
import globalReducer from './globalreducer';
import { CircularProgress } from '@mui/material';

const GlobalStore = React.createContext();

const intialState = {
    authReady: false,
    global: {
        collapse: localStorage.getItem('collapse') === 'false',
        loginVisible: false,
        notification: 0,
    }
};

const mainReducer = (state, action) => {
    switch (action.type) {
        case 'AUTH_READY':
            return { ...state, authReady: true }
        case 'COMPANY_LOADED':
            localStorage.setItem('company', JSON.stringify(action.payload));
            return { ...state, company: action.payload }
        default:
            return {
                ...state,
                global: globalReducer(state.global, action),
            }
    }
}

const OtherChild = ({ children }) => {
    const [state, dispatch] = React.useContext(GlobalStore);
    useEffect(() => {
        getAuth(fireApp).onAuthStateChanged(v => {
            dispatch({ type: 'AUTH_READY' })
        })
    }, [])
    return <React.Fragment>
        {state.authReady ? children :
            <div style={{ position: 'relative', float: 'left', top: '50%', left: '50%' }}>
                <CircularProgress />
            </div>
        }
    </React.Fragment>
}

const StoreProvider = props => {
    const store = useReducer(mainReducer, intialState)
    return <GlobalStore.Provider value={store}>
        <OtherChild {...props} />
    </GlobalStore.Provider>
}

export { GlobalStore, StoreProvider }