import { Route, Redirect } from 'react-router-dom';
import fireApp from '../firebase'
import { getAuth } from "firebase/auth";
import TopNavigation from '../page/navigation/top';

const DefaultRoute = ({ component: Component, ...rest }) => {
    if (!getAuth(fireApp).currentUser) {
        return <Redirect to="/login" />
    }
    return (
        <Route {...rest} render={props => (
            <TopNavigation {...props}>
                <Component {...props} />
            </TopNavigation>
        )} />
    );
}

export default DefaultRoute;